import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const AuthenticatedRoute = ({ children }) => {
  const { isAuthenticated, isLoading, error } = useAuth0();

  // Handle loading state
  if (isLoading) {
    return <div>Loading authentication status...</div>;
  }

  // Handle errors
  if (error) {
    console.error('Authentication error:', error);
    return <Navigate to="/auth-error" />;
  }

  // Redirect if not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/need-to-log-in" />;
  }

  // Render children if authenticated
  return children;
};
